<template>
  <div
    draggable="true"
    class="flex items-center justify-between px-4 py-1 text-xs text-gray-600 bg-gray-800 border-b border-gray-600 cursor-pointer select-none group hover:bg-gray-700"
    @click.prevent="onView"
  >
    <div class="flex items-center truncate cursor">
      <div class="flex items-center">
        <div class="flex items-center justify-center object-cover w-6 h-6 mr-2 text-sm text-teal-600">
          <fa-icon
            icon="camera-movie"
            class="fa-fw"
          />
        </div>
        <div class="flex flex-col text-gray-200 truncate">
          <span class="group-hover:underline">{{ bookmark.name }}</span>
          <span
            v-if="bookmark.is_default"
            class="text-xs italic tracking-tight text-gray-400 capitalize"
          >default</span>
        </div>
      </div>
    </div>

    <div
      class="v-popover"
      @click.stop
    >
      <v-popover>
        <button
          class="text-gray-400 btn btn-transparent hover:text-gray-600 focus:outline-none focus:text-gray-500"
          @click.prevent
        >
          <fa-icon icon="ellipsis" />
        </button>

        <template slot="popover">
          <ul>
            <li>
              <a
                v-close-popover
                href="#"
                class="flex items-center justify-between py-1 hover:underline"
                @click.prevent="onRename"
              >
                Rename
              </a>
            </li>
            <li>
              <a
                v-close-popover
                href="#"
                class="flex items-center justify-between py-1 hover:underline"
                @click.prevent="onMakeDefault"
              >
                Make Default
              </a>
            </li>
            <li>
              <a
                v-close-popover
                href="#"
                class="flex items-center justify-between py-1 hover:underline"
                @click.prevent="onUpdate"
              >
                Update to Current View
              </a>
            </li>
            <li>
              <a
                v-close-popover
                href="#"
                class="flex items-center justify-between py-1 hover:underline"
                @click.prevent="onRemove"
              >
                Remove Bookmark
              </a>
            </li>
          </ul>
        </template>
      </v-popover>
    </div>
  </div>
</template>
<script>
const BookmarkRename = () => import('@modals/BookmarkRename')
const BookmarkDelete = () => import('@modals/BookmarkDelete')
export default {
  name: 'BookmarkRow',

  props: {
    bookmark: {
      type: Object,
      required: true
    }
  },

  methods: {
    onView () {
      this.$emit('view-bookmark', this.bookmark)
    },

    onUpdate () {
      this.$emit('update-bookmark', this.bookmark)
    },

    onRename () {
      this.$modal.show(
        BookmarkRename, {
          bookmark: this.bookmark
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    onRemove () {
      this.$modal.show(
        BookmarkDelete, {
          bookmark: this.bookmark
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        })
    },

    onMakeDefault () {
      this.$bus.$emit('bookmark:makedefault', this.bookmark)
    }

  }
}
</script>
